exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-feedback-tsx": () => import("./../../../src/pages/feedback.tsx" /* webpackChunkName: "component---src-pages-feedback-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menu-tsx": () => import("./../../../src/pages/menu.tsx" /* webpackChunkName: "component---src-pages-menu-tsx" */),
  "component---src-pages-news-events-tsx": () => import("./../../../src/pages/news-events.tsx" /* webpackChunkName: "component---src-pages-news-events-tsx" */),
  "component---src-pages-reservation-tsx": () => import("./../../../src/pages/reservation.tsx" /* webpackChunkName: "component---src-pages-reservation-tsx" */)
}

